import React from 'react';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import one from '../imgs/one.png';
import three from '../imgs/three.png';
import six from '../imgs/six.jpg';
import { AuthenticatedTemplate } from "@azure/msal-react";

function Album() {
  const navigate = useNavigate();
  const cards = [
    {
      name: "Zoho",
      url: "https://www.zoho.com/people",
      imgUrl: three
    },
    {
      name: "LMS",
      url: "/user",
      imgUrl: six
    },
    {
      name: "Unical Academy",
      url: "https://unicalacademy.com/home",
      imgUrl: one
    },
    {
      name: "HR Policy",
      url: "/user",
      imgUrl: three
    },
    {
      name: "Tejas",
      url: "/user",
      imgUrl: six
    },
    {
      name: "Zoho People",
      url: "/user",
      imgUrl: one
    },
    {
      name: "Certifications",
      url: "/user",
      imgUrl: three
    },
    {
      name: "Events",
      url: "/user",
      imgUrl: six
    },
    {
      name: "Documents",
      url: "/user",
      imgUrl: one
    }
  ];

  return (
    <AuthenticatedTemplate>
    <ThemeProvider theme={createTheme()}>
      <CssBaseline />
      <Container sx={{ py: 8 }} maxWidth="xl">
        <Grid container spacing={4}>
          {cards.slice(0, 9).map((card, index) => (
            <Grid item key={index} xs={12} sm={6} md={4}>
              <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column'}}>
                <CardMedia
                  component="div"
                  sx={{
                    // 16:9
                    pt: '56.25%',
                  }}
                  image={card.imgUrl}
                />
                <CardContent sx={{ flexGrow: 1 }}>
                  <Typography gutterBottom variant="h5" component="h2">
                    {card.name}
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button size="small" onClick={() => window.location.replace(card.url)}>Open</Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </ThemeProvider>
    </AuthenticatedTemplate>
  );
}

export default Album;
